import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5260 7284 c-662 -84 -1175 -564 -1301 -1219 -22 -109 -18 -438 5
-545 133 -611 603 -1073 1195 -1174 136 -23 406 -22 542 3 283 51 550 190 757
394 224 219 358 463 423 768 32 146 32 448 1 589 -103 468 -395 843 -816 1044
-202 97 -368 136 -601 141 -93 2 -186 1 -205 -1z m397 -98 c446 -79 822 -355
1021 -752 349 -694 70 -1533 -627 -1884 -103 -52 -259 -104 -381 -126 -129
-24 -372 -24 -495 0 -575 113 -1004 535 -1127 1109 -31 144 -30 399 1 542 38
176 106 344 196 485 47 73 209 270 223 270 4 0 23 15 42 33 164 151 403 267
651 317 119 23 377 27 496 6z"/>
<path d="M4516 6752 c-5 -9 48 -109 239 -452 74 -133 157 -283 171 -310 11
-19 14 -85 14 -272 l0 -248 168 -2 c487 -6 462 -7 473 14 6 10 8 61 5 121 -3
56 -1 108 3 116 7 11 27 13 88 9 143 -10 229 -67 271 -181 58 -153 29 -338
-66 -433 -56 -56 -110 -76 -211 -78 l-86 -1 -5 99 -5 100 -155 -2 -155 -2 -3
-245 -2 -245 250 0 c290 0 359 10 485 70 161 77 256 211 300 422 25 115 17
292 -17 383 -37 102 -117 226 -175 274 -52 44 -165 97 -244 115 -47 11 -129
16 -307 16 -236 1 -266 5 -244 33 10 13 16 25 204 372 72 132 139 254 149 271
39 64 38 64 -157 64 l-176 0 -106 -220 c-58 -121 -111 -220 -117 -220 -5 0
-60 98 -121 218 l-111 217 -176 3 c-96 1 -178 -1 -181 -6z"/>
<path d="M4577 3942 c-12 -2 -16 -9 -13 -19 4 -8 -12 -80 -35 -160 -29 -101
-38 -148 -30 -156 7 -7 13 -3 19 14 6 13 16 38 23 54 7 17 14 39 15 50 0 11
13 62 28 114 27 95 26 111 -7 103z"/>
<path d="M5369 3943 c-10 -2 -16 -15 -17 -32 0 -16 -8 -46 -18 -67 -9 -22 -18
-54 -20 -72 -2 -18 -11 -47 -21 -65 -25 -44 -20 -107 9 -107 16 0 19 5 15 21
-4 16 48 221 78 306 5 15 -5 21 -26 16z"/>
<path d="M4185 3911 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
<path d="M5708 3840 c-18 -18 -24 -30 -17 -37 6 -6 3 -32 -11 -74 -26 -85 -27
-129 -1 -129 11 0 23 5 26 10 3 6 1 10 -4 10 -16 0 -14 22 11 106 16 53 29 79
42 84 16 6 17 8 3 13 -10 4 -14 13 -10 26 7 28 -6 25 -39 -9z"/>
<path d="M6756 3818 c-16 -9 -16 -11 -1 -22 14 -10 14 -19 -8 -94 -22 -76 -22
-102 3 -87 5 3 19 35 30 71 33 107 23 159 -24 132z"/>
<path d="M6876 3818 c-16 -9 -16 -11 -2 -21 15 -11 14 -20 -5 -95 -22 -85 -19
-109 14 -96 12 4 15 13 11 29 -3 12 2 46 10 76 27 91 17 132 -28 107z"/>
<path d="M3917 3814 c-4 -4 0 -12 8 -18 10 -8 17 -39 22 -104 6 -95 15 -117
32 -84 6 12 5 50 -2 112 -11 83 -14 95 -32 98 -12 2 -24 0 -28 -4z"/>
<path d="M4050 3812 c0 -5 7 -22 15 -38 11 -23 15 -25 20 -12 10 26 2 52 -18
56 -9 2 -17 -1 -17 -6z"/>
<path d="M4156 3805 c3 -8 -4 -53 -17 -100 -23 -89 -19 -115 16 -102 10 5 14
14 10 30 -4 13 3 56 14 97 26 93 26 90 -4 90 -17 0 -23 -4 -19 -15z"/>
<path d="M4285 3800 c6 -17 -17 -110 -41 -175 -4 -9 0 -15 9 -15 18 0 58 79
62 120 1 14 5 40 9 58 7 30 6 32 -19 32 -22 0 -25 -3 -20 -20z"/>
<path d="M4379 3804 c-12 -15 -11 -16 8 -10 32 10 35 -6 13 -76 -25 -81 -26
-121 -2 -116 10 2 17 13 18 28 1 14 8 53 17 86 25 90 23 104 -12 104 -16 0
-35 -7 -42 -16z"/>
<path d="M4640 3805 c0 -18 8 -18 40 0 l25 14 -32 1 c-23 0 -33 -5 -33 -15z"/>
<path d="M4749 3813 c-1 -5 0 -19 0 -33 1 -14 -5 -45 -14 -69 -30 -85 -14
-128 37 -99 l23 13 -23 3 c-24 4 -24 7 4 106 19 68 18 86 -6 86 -11 0 -20 -3
-21 -7z"/>
<path d="M4875 3773 c-16 -47 -26 -93 -29 -143 -1 -19 4 -25 19 -25 16 0 19 6
17 30 -1 17 7 61 18 100 24 83 24 85 6 85 -7 0 -21 -21 -31 -47z"/>
<path d="M5002 3808 c-20 -20 -14 -59 19 -109 26 -40 29 -51 19 -69 -16 -30
-8 -34 18 -10 31 29 27 57 -13 114 -27 38 -32 52 -24 67 11 21 -1 25 -19 7z"/>
<path d="M5084 3798 c3 -13 6 -27 6 -33 0 -5 5 -3 10 5 15 23 12 50 -6 50 -12
0 -15 -6 -10 -22z"/>
<path d="M5457 3781 c-41 -46 -64 -113 -50 -151 10 -30 40 -37 68 -16 19 15
19 15 -7 16 -26 0 -28 3 -28 40 0 44 20 91 53 128 11 12 16 22 10 22 -6 0 -27
-18 -46 -39z"/>
<path d="M5573 3813 c-7 -2 -13 -19 -13 -37 0 -17 -8 -47 -18 -66 -9 -19 -17
-51 -17 -70 0 -28 4 -35 20 -35 14 0 19 6 18 20 -1 11 5 45 13 75 36 129 36
129 -3 113z"/>
<path d="M5850 3794 c-70 -59 -84 -194 -19 -194 9 0 22 7 29 15 10 13 9 15 -9
15 -12 0 -24 7 -28 17 -10 25 14 101 43 139 32 41 26 43 -16 8z"/>
<path d="M5952 3808 c-7 -7 -12 -26 -12 -42 0 -17 -8 -49 -18 -71 -22 -52 -18
-95 10 -95 15 0 19 5 15 19 -3 11 6 57 19 103 13 46 24 86 24 91 0 11 -25 8
-38 -5z"/>
<path d="M6207 3786 c-88 -88 -61 -210 41 -180 l27 8 -30 4 c-42 5 -55 19 -55
58 0 42 16 83 42 111 35 38 13 37 -25 -1z"/>
<path d="M6306 3794 c-8 -31 -3 -39 19 -30 10 3 15 15 13 28 -4 31 -25 33 -32
2z"/>
<path d="M6430 3794 c-39 -33 -60 -74 -60 -116 0 -39 7 -51 37 -67 32 -16 36
-14 13 9 -11 11 -20 28 -20 38 0 38 23 94 52 127 35 41 23 46 -22 9z"/>
<path d="M6528 3808 c27 -27 6 -135 -31 -167 -27 -22 -19 -33 12 -17 32 17 71
87 71 127 0 31 -26 69 -48 69 -12 0 -13 -3 -4 -12z"/>
<path d="M6653 3806 c3 -7 -6 -53 -20 -101 -18 -61 -22 -90 -14 -98 14 -14 47
62 64 149 l14 64 -25 0 c-16 0 -22 -4 -19 -14z"/>
<path d="M5219 3797 c-9 -7 -28 -30 -43 -51 -28 -42 -36 -112 -14 -134 17 -17
52 -15 71 6 15 16 14 17 -16 17 -26 0 -33 4 -35 23 -4 29 14 77 45 120 25 33
21 41 -8 19z"/>
<path d="M4586 3741 c-8 -13 12 -105 28 -126 9 -13 17 -15 30 -8 15 8 16 11 2
22 -8 7 -20 37 -27 67 -12 52 -21 65 -33 45z"/>
<path d="M4940 3630 c0 -22 4 -28 16 -23 8 3 13 12 10 20 -3 8 -6 19 -6 24 0
5 -4 9 -10 9 -5 0 -10 -13 -10 -30z"/>
<path d="M6034 3629 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
